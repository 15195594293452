// .short {
//   // width: 50%;
// }
.flex-row {
  display: flex;
  flex-direction: row;
}

.info-container {
  margin-top: 40px;
  margin-left: 10px;
  .worker-name {
    margin-bottom: 53px;
  }
}
