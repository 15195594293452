.double-layout-container {
  display: flex;
  flex-direction: row;
  flex: 50%;
  .column {
    display: flex;
    flex: 50%;
    .product-info {
      .main-product {
      }
      .related-products {
        margin-left: 20px;
        .related-product {
        }
      }
    }
  }
}
