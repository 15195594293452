.single-layout {
  form {
    .container-fluid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > .row {
        margin-left: 0px;
        margin-right: 0px;
        width: 49%;

        > div {
          padding-left: 0px;
          padding-right: 0px;
          display: flex;
          align-items: center;
          > div {
            width: 100%;
          }
          label {
            margin-bottom: 8px !important;
          }
        }
      }
    }
  }
}

form.variant-form {
  .container-fluid {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    > .row {
      width: 100%;
    }
  }
}

.products-page {
  form.product-form {
    .container-fluid {
      > .row {
        &:last-child {
          width: 100%;
        }
      }
    }
  }
  form.packages-form {
    margin-top: 8px;
    .container-fluid {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      > .row {
        width: 100%;
        input {
          margin-bottom: 8px;
        }
      }
    }
  }
}

form.product-type-form {
  .container-fluid {
    > .row {
      width: 100%;
    }
  }
}

.full-width-form {
  .container-fluid {
    > .row {
      width: 100% !important;
    }
  }
}

.last-child-full-width-form {
  .container-fluid {
    > .row {
      &:last-child {
        width: 100% !important;
      }
    }
  }
}

.column-full-width-form {
  .container-fluid {
    flex-direction: column;
    > .row {
      &:last-child {
        width: 100% !important;
      }
    }
  }
}
