@import "./typography.scss";
@import "./helpers.scss";
@import "./pages.scss";

.action-button {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.navbar-nav {
  .active {
    background-color: #1266f1;
    font-weight: bold;
    border-radius: 0.25rem;
    a {
      color: white;
    }
  }
}

.navbar-collapse.show {
  li {
    a {
      padding-left: 10px;
    }
  }
}
.navbar-collapse.collapsing {
  li {
    a {
      padding-left: 10px;
    }
  }
}

.split-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .view {
    flex: 1;
    .item-container {
      p {
        margin: 0;
      }
    }
  }
  .view.second {
    display: flex;
    flex-direction: row;
    .column {
      flex: 50%;
    }
  }
}
