.pagination {
  display: flex;
  justify-content: flex-end;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 8px;
  text-decoration: none;
}

// .pagination a:hover:not(.selected) {
//   background-color: #ddd;
//   border-radius: 5px;
// }

.pagination .selected {
  color: #fff;
  background-color: #1266f1;
  border-radius: 5px;
}

.table-action {
  margin-right: 0.5rem;
  cursor: pointer;
}

.top-nav {
  .form-outline {
    max-width: 250px;
    .table-search {
    }
    .form-label {
      font-weight: normal;
    }
  }
}

.table {
  margin-bottom: 0px;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 1.4rem;
}

.table td {
  vertical-align: middle;
}
