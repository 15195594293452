.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    min-width: 550px;
    .rjsf-form {
      width: 100%;
      .rjsf {
        > div {
          margin-bottom: 30px;
        }
      }
    }
    img {
      width: 150px;
      margin-bottom: 40px;
    }
    .buttons {
      margin-bottom: 0 !important;
      > button {
        width: 100%;
      }
    }
  }
}
