.mini-table {
  .mini-table-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2.5px 5px;
    .mini-table-cell {
      &:first-child {
        flex: 15%;
      }
      flex: 33%;
    }
  }
  .mini-table-header {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .mini-table-data {
  }
}
